import React, { Suspense, useMemo } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { Box, OrbitControls, Plane } from "@react-three/drei";
import { NoColorSpace, TextureLoader } from "three";
import { useControls } from "leva";

const Terrain = () => {
  const height = useLoader(
    TextureLoader,
    "http://localhost:8012/local/ei_common.png"
  );
  const normals = useLoader(
    TextureLoader,
    "http://localhost:8012/local/ei_normal_map.png"
  );
  normals.colorSpace = NoColorSpace;

  const scales = useControls({
    normalScale: [-1, 1],
    displacementScale: 20,
  });

  return (
    <group>
      <Plane
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -3, 0]}
        args={[(1144 / 768) * 64, 64, 1144, 768]}
      >
        <meshPhongMaterial
          attach="material"
          color="white"
          // metalness={0.2}
          {...scales}
          normalMap={normals}
          displacementMap={height}
          normalMap-colorSpace={NoColorSpace}
        />
      </Plane>
    </group>
  );
};

export default function App() {
  const { xz, y } = useControls({
    xz: { x: 0, y: 0 },
    y: 0,
  });
  const position = useMemo(() => [
    20 + xz.x * 300,
    5 + y * 300,
    1 + xz.y * 300,
  ]);
  const lightControl = useControls({ intensity: 200 });
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 10,
      }}
    >
      <Canvas camera={{ position: [50, 50, -20] }} frameloop="demand">
        <OrbitControls />
        <Box position={position} emissive="yellow" emissiveIntensity={10} />
        <pointLight intensity={200} position={position} {...lightControl} />
        <Suspense>
          <Terrain />
        </Suspense>
      </Canvas>
    </div>
  );
}
